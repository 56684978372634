<template>
  <header-selete placeholder="搜索需求、合同编号" ref="headerSelete" fixed @submit="updatePage" />
  <div class="fen-lei">
    <van-tabs v-model:active="pageType" @change="updatePage">
      <van-tab
          :title="vo.title"
          v-for="vo in navList"
      />
    </van-tabs>
  </div>
  <div style="height: 44px;"></div>
  <xia-la-load ref="xiaLa" @load="getLoad">
    <din-dan-zhuan-tai :xiang-mu-info="vo" v-for="(vo,index) in dataList" :key="index"/>
  </xia-la-load>

  <footer-nav fixed/>
</template>

<script>
import HeaderSelete from "../open/headerSelete";
import FooterNav from "../open/footerNav";
import DinDanZhuanTai from "./public/dinDanZhuanTai";
import XiaLaLoad from "../../components/list/XiaLaLoad";
import http from "../../api/http";
import XEUtils from "xe-utils";
export default {
  name: 'WoChengJieDeXiangMu',
  components: {XiaLaLoad, DinDanZhuanTai, FooterNav, HeaderSelete},
  mixins: [],
  data() {
    return {
      pageType:XEUtils.toNumber(this.$route.query.typeName) ,
      where: {},
      dataList: [],
      navList: [
        {title: '全部', type: '全部'},
        {title: '已报价', type: '已报价'},
        {title: '进行中', type: '进行中'},
        {title: '已完成', type: '已完成'},
      ]
    }
  },
  mounted() {
  },
  methods: {
    updatePage() {
      this.$refs.xiaLa.resetData()
    },
    getLoad(page) {
      this.where.page = page
      this.where.data_tyoe = this.navList[this.pageType].type
      this.where.keyword = this.$refs.headerSelete.getKeyword()
      this.where.pageSize = 5
      http.get('/gzhphp/user_xiang_mu/getWoChengJieDeXiangMu', {params: this.where}).then(da => {
        this.$refs.xiaLa.setLoadSuccess2(da).then(({page, item}) => {
          item = item.map(n => n) //修改数据
          if (page === 1) this.dataList = item
          else this.dataList = [...this.dataList, ...item]
        })
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>
.fen-lei {
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 10;
}
</style>

<template>
  <div class="list-item">
    <div class="list-item-order" v-if="xiangMuInfo.ywht_order_number">订单号：{{ xiangMuInfo.ywht_order_number }}</div>
    <div class="list-item-order" v-if="xiangMuInfo.code">合同编号：{{ xiangMuInfo.code }}</div>
    <div class="list-item-name">{{ xiangMuInfo.xu_qiu_title }}</div>
    <div class="list-item-status">
      <div>
        当前状态：<a href="javascript:">{{ xiangMuInfo.zhuang_tai }}</a>
      </div>
      <div>
        <router-link
            :to="{name:'AddHetong',params: {ywht_id:xiangMuInfo.ywht_id}}"
            v-if="is_show_shang_chuan_he_tong"
        >
          上传合同
        </router-link>
        &nbsp;&nbsp;
        <router-link :to="{name:'XiangMuInfo',params:{xiang_mu_id:xiangMuInfo.xu_qiu_id}}">
          查看详情
        </router-link>
      </div>
    </div>

    <div v-for="v2 in getXiangMuInfo.kan_ban">
      <div class="xiang-mu-type">
        项目类型：{{ v2.join_min_type.name }}
      </div>
      <div class="xiang-li-liu-cheng">
        <jin-du-xiang-xi :min="v2.zhanKai" :liu-cheng="v2.join_liu_cheng_all"/>
      </div>
      <div style="text-align: right;margin: 5px 0">
<!--        <router-link
            :to="{name:'PingLun',params:{kan_ban_id:v2.id}}"
           v-if="v2.isPinLun"
        >
          评论
        </router-link>-->
        &nbsp;
        <a href="javascript:" @click.stop.prevent="v2.zhanKai = !v2.zhanKai">
          <template v-if="v2.zhanKai"> 收起详情</template>
          <template v-else> 查看详细进度</template>
        </a>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'dinDanZhuanTai',
}
</script>
<script setup>
import {defineProps, computed} from 'vue'
import JinDuXiangXi from "./jinDuXiangXi";
import XEUtils from "xe-utils";
import {getUserInof} from "@/api/cookie";
import {XIANG_MU_STATUS} from "@/api/dataList/changLiang";

let props = defineProps({
  xiangMuInfo: Object
})

const userInfo = getUserInof()

//是否显示上传合同
let is_show_shang_chuan_he_tong = computed(() => {
  //不是进行中
  if (props.xiangMuInfo.zhuang_tai !== '进行中') {
    return false
  }
  //已上传
  if (XEUtils.toInteger(props.xiangMuInfo.ywhtfj_he_tong_id) > 0) {
    return false
  }
  //不是本人接单的
  if (userInfo.id != props.xiangMuInfo.can_yu_lxr_id) {
    return false
  }

  return true;
})

let getXiangMuInfo = computed(() => {
  let data = props.xiangMuInfo
  if (XEUtils.isArray(data.kan_ban)) {
    data.kan_ban.map(n => {
      n.isPinLun = true;

      if(n.xiang_mu_status != XIANG_MU_STATUS.WAN_CHENG){

        //项目没完成
        n.isPinLun = false
      }
      if(n.ke_hu_man_yi_du > 0){
        //已经评论过
        n.isPinLun = false
      }
      return n
    })
  }
  return data
})


</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.list-item {
  background-color: @white;
  box-shadow: @yin-ying;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;

  .list-item-order {
    margin-bottom: 5px;
  }

  .list-item-name {
    color: @gray-6;
    margin-bottom: 5px;
  }

  .xiang-li-liu-cheng {
    background-color: @gray-2;
    padding: 5px;
  }

  .xiang-mu-type {
    margin: 5px 0;
  }

  .list-item-status {
    color: @gray-6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

}
</style>
